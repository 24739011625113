import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useIsMounted } from "../../../../hooks";
import { useAlert, useAuth } from "../../../../providers";
import {
  authService,
  bankService,
  cityService,
  userService,
} from "../../../../../infrastructure/services";
import { Constants } from "../../../../utils";
import { useLoadingContext } from "../../../../context/loading.context";
import { useNavigate } from "react-router-dom";

const useAdminUserFormController = ({ userId }) => {
  const genders = [
    { id: 0, label: "SELECIONE", value: "N" },
    { id: 1, label: "Masculino", value: "M" },
    { id: 2, label: "Feminino", value: "F" },
  ];
  const bankAccountTypes = [
    Constants.defaultSelectPlaceholder,
    { id: 1, label: "CC - Conta Corrente" },
    { id: 2, label: "CP - Conta Poupança" },
  ];
  const userLevels = [
    { id: 0, label: "SELECIONE", value: "N" },
    { id: 1, label: "Principal", value: "P" },
    { id: 2, label: "Secundário", value: "S" },
  ];

  const schema = yup.object().shape(
    {
      userType: yup
        .number()
        .required("Campo obrigatório")
        .test("invalid", "Selecione uma das opções", (value) => {
          return value > 0;
        }),
      groupType: yup
        .number()
        .required("Campo obrigatório")
        .test("invalid", "Selecione uma das opções", (value) => {
          return value > 0;
        }),
      profileType: yup
        .number()
        .required("Campo obrigatório")
        .test("invalid", "Selecione uma das opções", (value) => {
          return value > 0;
        }),
      userLevel: yup
        .number()
        .required("Campo obrigatório")
        .test("invalid", "Selecione uma das opções", (value) => {
          return value > 0;
        }),

      primaryUser: yup
        .object()
        .optional()
        .when("userLevel", {
          is: (value) => value == 2,
          then: (rule) =>
            rule.test("invalid", "Selecione uma das opções", (value) => {
              return value.id > 0;
            }),
        }),

      displayName: yup.string().required("Campo obrigatório"),
      email: yup.string().required("Campo obrigatório"),
      cpf: yup.string().required("Campo obrigatório"),

      rg: yup.string().optional(),
      phone: yup.string().optional(),
      branch: yup.string().optional(),
      birthDate: yup.date().nullable().typeError("Data inválida"),
      gender: yup.number().optional(),
      cnh: yup.string().optional(),
      senha: yup.string().optional(),
      bank: yup.object().optional(),
      bankAccountType: yup.number().optional(),
      bankAgency: yup.string().optional(),
      bankAccount: yup.string().optional(),
      pix: yup.string().required("Campo obrigatório"),
      address: yup.string().optional(),
      addressNumber: yup.string().optional(),
      addressCep: yup.string().optional(),
      addressDistrict: yup.string().optional(),
      addressState: yup
        .object()
        .optional()
        .when("addressState", {
          is: (value) => value?.id,
          then: (rule) =>
            rule.test("invalid", "Selecione uma das opções", (value) => {
              return value.id > 0;
            }),
        }),
      addressCity: yup
        .object()
        .optional()
        .when("addressCity", {
          is: (value) => value?.id,
          then: (rule) =>
            rule.test("invalid", "Selecione uma das opções", (value) => {
              return value.id > 0;
            }),
        }),
    },
    [
      ["addressState", "addressState"],
      ["addressCity", "addressCity"],
      ["primaryUser", "primaryUser"],
    ]
  );

  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      userType: 0,
      groupType: 0,
      profileType: 0,
      userLevel: 0,
      displayName: "",
      email: "",
      cpf: "",
      rg: "",
      phone: "",
      branch: "",
      birthDate: "",
      gender: 0,
      cnh: "",
      bank: Constants.defaultSelectPlaceholder,
      bankAccountType: 0,
      bankAgency: "",
      bankAccount: "",
      pix: "",
      senha: "",
      address: "",
      addressNumber: "",
      addressCep: "",
      addressDistrict: "",
      addressState: Constants.states[0],
      addressCity: Constants.defaultSelectPlaceholder,
      primaryUser: Constants.defaultSelectPlaceholder,
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const { user } = useAuth();
  const { showError, showSuccess } = useAlert();
  const isMounted = useIsMounted();
  const { setLoadingState } = useLoadingContext();

  const [userTypes, setUserTypes] = useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [groupTypes, setGroupTypes] = useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [profileTypes, setProfileTypes] = useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [banks, setBanks] = useState([Constants.defaultSelectPlaceholder]);
  const [cities, setCities] = useState([Constants.defaultSelectPlaceholder]);
  const [withPrimary, setWithPrimary] = useState(false);
  const [situation, setSituation] = useState(null);
  const [primaryUsers, setPrimaryUsers] = useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!user) {
      setLoadingState(true);
      authService
        .getUsersPermissions()
        .then((permissions) => {
          if (isMounted.current) {
            setUserTypes([
              Constants.defaultSelectPlaceholder,
              ...permissions.userTypes.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
            setGroupTypes([
              Constants.defaultSelectPlaceholder,
              ...permissions.groupTypes.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
            setProfileTypes([
              Constants.defaultSelectPlaceholder,
              ...permissions.profileTypes.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      bankService
        .getBanks()
        .then((response) => {
          if (isMounted.current) {
            setBanks([
              Constants.defaultSelectPlaceholder,
              ...response.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      userService
        .getActiveUsers({ mode: "TODOS" })
        .then((response) => {
          setPrimaryUsers([
            Constants.defaultSelectPlaceholder,
            ...response.map((item) => ({
              id: item.id,
              label: item.displayName,
            })),
          ]);
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!!userId && banks.length > 1 && primaryUsers.length > 1) {
      fetchUser();
    } else {
      getCities({ state: Constants.states[0].label });
    }
  }, [userId, banks, primaryUsers]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "addressState") {
        if (value.addressState != null) {
          getCities({ state: value.addressState.label });
        }
      } else if (name == "userLevel") {
        if (value.userLevel != null) {
          if (value.userLevel == 2) {
            setWithPrimary(true);
          } else {
            setValue("primaryUser", Constants.defaultSelectPlaceholder);
            setWithPrimary(false);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchUser = () => {
    setIsLoading(true);

    const resetUser = (user, cities) => {
      reset({
        userType: user.userType,
        groupType: user.groupType,
        profileType: user.profileType,
        userLevel:
          user.bankAccountType != 0
            ? userLevels.filter((level) => level.value == user.userLevel)[0].id
            : 0,
        displayName: user.displayName,
        email: user.email,
        cpf: user.cpf,
        rg: user.rg,
        phone: user.phone,
        branch: user.branch,
        birthDate: user.birthDate,
        gender: !!user.gender
          ? genders.filter((gender) => gender.value == user.gender)[0].id
          : 0,
        cnh: user.cnh,
        bank: !!user.bank
          ? banks.filter((bank) => bank.id == user.bank)[0]
          : Constants.defaultSelectPlaceholder,
        bankAccountType:
          user.bankAccountType != 0
            ? bankAccountTypes.filter(
                (type) => type.label.split(" - ")[0] == user.bankAccountType
              )[0].id
            : 0,
        bankAgency: user.bankAgency,
        bankAccount: user.bankAccount,
        pix: user.pix,
        address: user.address,
        addressNumber: user.addressNumber,
        addressCep: user.addressCep,
        addressDistrict: user.addressDistrict,
        addressState: Constants.states.filter(
          (state) => state.label == user.addressState
        )[0],
        addressCity: cities.filter((city) => city.id == user.addressCity)[0],
        primaryUser:
          user.primaryUser != null
            ? primaryUsers.filter(
                (primary) => primary.id == user.primaryUser
              )[0]
            : Constants.defaultSelectPlaceholder,
      });
      setSituation(user.situation);
      setWithPrimary(user.primaryUser != null);
      setIsLoading(false);
    };

    setLoadingState(true);
    userService
      .getUser({ id: userId })
      .then((user) => {
        if (user.addressCity != null && user.addressState != null) {
          const state = Constants.states.filter(
            (state) => state.label == user.addressState
          )[0].label;

          setLoadingState(true);
          cityService
            .getCities({ state: state })
            .then((response) => {
              if (isMounted.current) {
                const newCities = [
                  Constants.defaultSelectPlaceholder,
                  ...response.map((item) => ({
                    id: item.id,
                    label: item.name,
                  })),
                ];
                setCities(newCities);
                resetUser(user, newCities);
              }
            })
            .catch(showRequestError)
            .finally(() => {
              setLoadingState(false);
            });
        } else {
          resetUser(user, cities);
        }
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  const getCities = ({ state }) => {
    setLoadingState(true);
    cityService
      .getCities({ state: state })
      .then((response) => {
        if (isMounted.current) {
          setCities([
            Constants.defaultSelectPlaceholder,
            ...response.map((item) => ({
              id: item.id,
              label: item.name,
            })),
          ]);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSubmit = handleSubmit((data) => {
    const tipoConta = bankAccountTypes
      .filter((type) => type.id == data.bankAccountType)[0]
      .label.split(" - ")[0];

    const sexo = genders.filter((gender) => gender.id == data.gender)[0].value;

    const form = {
      clienteId: userId ?? null,

      tipoUsuario: data.userType,
      grupoUsuario: data.groupType,
      perfilSubUsuario: data.profileType,
      nivel: userLevels.filter((level) => level.id == data.userLevel)[0].value,
      usuarioPrincipalId: !!data.primaryUser
        ? data.primaryUser.id != 0
          ? data.primaryUser.id
          : null
        : null,

      nome: !!data.displayName ? data.displayName : null,
      email: !!data.email ? data.email : null,
      cpf: !!data.cpf ? data.cpf : null,
      celular: !!data.phone ? data.phone : null,
      ramal: !!data.branch ? data.branch : null,
      sexo: sexo != "N" ? sexo : null,
      dataNasc: data.birthDate,
      rg: !!data.rg ? data.rg : null,
      cnh: !!data.cnh ? data.cnh : null,
      senha: !!data.senha ? data.senha : null,

      pagoVoip: null,
      loginVoip: null,
      senhaVoip: null,
      vencVoip: null,

      idLoja: null,
      adminLoja: null,

      pix: !!data.pix ? data.pix : null,
      bancoId: data.bank.id != 0 ? data.bank.id : null,
      tipoConta: tipoConta != "SELECIONE" ? tipoConta : null,
      numeroAgencia: !!data.bankAgency ? data.bankAgency : null,
      numeroConta: !!data.bankAccount ? data.bankAccount : null,

      logradouro: !!data.address ? data.address : null,
      numero: !!data.addressNumber ? data.addressNumber : null,
      cep: !!data.addressCep ? data.addressCep : null,
      bairro: !!data.addressDistrict ? data.addressDistrict : null,
      cidadeId: !!data.addressCity
        ? data.addressCity.id != 0
          ? data.addressCity.id
          : null
        : null,

      permissaoLink: null,
      horaInicialAcesso: null,
      horaFinalAcesso: null,
      foregonId: null,
      instagram: null,
      facebook: null,
    };

    setIsLoading(true);

    if (!!userId) {
      setLoadingState(true);
      userService
        .editUser({ user: form })
        .then(() => {
          fetchUser();
          showSuccess("Usuário editado!");
          setIsLoading(false);
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      setLoadingState(true);
      userService
        .createUser({ user: form })
        .then(() => {
          navigate("/admin/usuarios");
          showSuccess("Usuário cadastrado!");
          setIsLoading(false);
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  const onToggleSituation = () => {
    setIsLoading(true);

    setLoadingState(true);
    userService
      .toggleUserSituation({ id: userId })
      .then(() => {
        showSuccess(
          situation == 1 ? "Usuário Suspenso!" : "Usuário Reativado!"
        );
        setSituation(situation == 1 ? 0 : 1);
        setIsLoading(false);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    control,
    userTypes,
    groupTypes,
    profileTypes,
    userLevels,
    genders,
    banks,
    bankAccountTypes,
    addressStates: Constants.states,
    addressCities: cities,
    primaryUserOptions: primaryUsers,
    withPrimary: withPrimary,
    isToEdit: !!userId,
    onSubmit,
    situation,
    onToggleSituation,
    isLoading,
  };
};

export default useAdminUserFormController;
